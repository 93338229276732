<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('CustomPeriodicTaskCreate')"
    @edit="(id) => onEdit('CustomPeriodicTaskEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'CustomPeriodicTasks',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'CustomPeriodicTasks',
      tableCaption: 'Кастомные периодические задачи',
      breadcrumbs: [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Кастомные периодические задачи',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        {
          text: 'Дата создания задачи',
          alias: 'createDate',
          order: 'createDate',
        },
        { text: 'Название задачи', alias: 'name', order: 'name' },
        { text: 'Имя папки задачи', alias: 'folder', order: 'folder' },
        {
          text: 'Активная',
          alias: 'isEnabled',
          order: 'isEnabled',
        },
        { text: 'Периодичность', alias: 'scheduleMode', order: 'scheduleMode' },
        {
          text: 'Дата и время первого запуска, и время для последующих запусков',
          alias: 'scheduledTime',
          order: 'scheduledTime',
        },
        {
          text: 'Время последнего запуска',
          alias: 'lastStartTime',
          order: 'lastStartTime',
        },
        {
          text: 'Время последнего окончания',
          alias: 'lastFinishTime',
          order: 'lastFinishTime',
        },
        { text: 'Успешно или нет', alias: 'lastSuccess', order: 'lastSuccess' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
